<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ข้อมูลกลุ่มตัวแทน</div>
      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ชื่อกลุ่มตัวแทน"
          outlined
          rounded
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มกลุ่มตัวแทน</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      @click:row="edit"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
    >
      <!-- <template v-slot:[`item.status`]="{ item }">
        <menu-ship small v-model="item.status"/>
      </template> -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    pageCount: 0,
    headers: [
      { text: 'ชื่อตัวแทน', value: 'name' },
      { text: 'รายละเอียดรถ', value: 'detail' },
      { text: 'สาขา', value: 'branch_id' },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],
    list: [],
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: ''
    }
  }),

  created() {
    this.getData();
  },

  methods: {
    add() {
      this.$router.push({ name: 'customer-agent-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'customer-agent-update',
        params: { id: item._id }
      });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { discounted_groups_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };

            await this.$axios
              .post(
                `${this.$baseUrl}/discounted_groups/delete_discounted_groups`,
                body
              )
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: res.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/discounted_groups/get_agent`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: res.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
